<template>
  <div>
    <v-switch
      :label="$t('infinite')"
      v-model="availability.infinite"
      @input="updateAvailability"
    />
    <v-text-field
      filled
      dense
      :label="$t('current')"
      type="number"
      v-model="availability.current"
      @input="updateAvailability"
    />
    <v-text-field
      filled
      dense
      :label="$t('target')"
      type="number"
      v-model="availability.target"
      @input="updateAvailability"
    />
    <v-text-field
      filled
      dense
      :label="$t('threshold')"
      type="number"
      v-model="availability.threshold"
      @input="updateAvailability"
    />
  </div>
</template>

<script>
export default {
	name: "Availability",
	props: {
		value: {
      type: Object,
      default: () => {}
    }
	},
	data() {
		return {
			resetObject: null,
			availability: null,
		}
	},
	beforeMount() {
		if(this.value !== undefined && this.value !== null) {
			this.resetObject = JSON.parse(JSON.stringify(this.value))
			this.availability = this.value
		}
		if(this.value === null || this.value === undefined) {
			this.availability = {}
			this.availability.infinite = false
			this.availability.current = 0
			this.availability.target = 0
			this.availability.threshold = 0
		}
	},
	beforeDestroy() {
		this.$emit('input', this.resetObject)
	},
	methods: {
		updateAvailability() {
			this.$emit('input', this.availability)
		}
	}
}
</script>

<style scoped>

</style>